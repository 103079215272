import { DATE_FORMAT } from '../../utilities/dateUtils';
import { Prompt, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { first, get, isEmpty } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';

import {
  APPROVE_LOCATION_SUCCESS,
  CREATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_SOFTWARE_SUCCESS,
  addLocationPhoto,
  approveLocation,
  changeLocationPhotoStatus,
  createLocation,
  getLocation,
  rejectLocation,
  removeLocationPhoto,
  updateLocation,
  updateLocationPhotosOrder,
  updatePodSoftware,
  uploadLocationPhoto
} from './location.actions';
import { APP_DISPLAY_LEVELS, HARDWARE_TYPES, LIFECYCLE_STATUS, LOCATION_FIELDS, LOCATION_TYPES } from '../../types/location.types';
import { DEFAULT_LOCATION } from '../../utilities/googleMapsUtils';
import {
  HARDWARE_FIELDS,
  HARDWARE_FIELDS_ALL,
  HARDWARE_FIELDS_NO_TECH_POD,
  HARDWARE_FIELDS_SMART_LOCK,
  HARDWARE_FIELDS_SMART_POD,
  HARDWARE_FIELDS_TTLOCK,
  setPodHardwareTypeDefaultValues,
} from './editComponents/hardwareFields';
import { handleToastMessage, setDirtyForm, setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';
import CreateLocationDialog from './editComponents/createLocationDialog.component';
import EditLocation from './editLocation.component';
import EditPrebuiltLocation from './editPrebuiltLocation.component';
import LocationToolbar, { TABS } from './locationToolbar.component';
import MonitoringDebug from '../monitoring/locationDebug/locationDebug.container';
import MonitoringOverviewComponent from '../monitoring/locationOverview/locationOverview.container';
import PhotoCard from './editComponents/photoCard.component';
import ReviewComponent from './reviews.component';

// MA-3123 Default settings on create: "DISPLAY_NEVER", isLimitedAccess = false
const DEFAULT_VALUES = { 
  lifecycleStatus: LIFECYCLE_STATUS.WAITING_TO_ACTIVATE,
  isUserSubmitted: false,
  isLimitedAccess: false,
  appDisplayLevel: APP_DISPLAY_LEVELS.DISPLAY_NEVER
};

class LocationContainer extends Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(this.props.location.search);

    this.state = {
      selectedLocation: {},
      imageGrow: true,
      selectedTab: queryParams.get('tab') ? TABS[queryParams.get('tab')] : TABS.LOCATION_DETAILS,
      imageLoading: false,
      isAdminMode: false,
    };
  }

  async componentDidMount() {
    this.props.setPageTitle('Location');
    this.props.setDirtyForm(false);

    if (this.props.match.params.id === 'create') {
      // MA-3123 Default settings on create: "DISPLAY_NEVER", isLimitedAccess = false
      const buildLocation = this.buildLocation(DEFAULT_VALUES);
      this.setState({ selectedLocation: buildLocation, isCreate: true });
    } else {
      await this.props.getLocation(this.props.match.params.id);
      if (!isEmpty(this.props.selectedLocation)) {
        this.setState({ selectedLocation: this.props.selectedLocation, isCreate: false }, () => {
          this.props.setPageTitle(`${this.props.selectedLocation.type === LOCATION_TYPES.MAMAVA ? 'Mamava Pod' : 'Lactation Space'}: ${this.props.selectedLocation.name}`, '/locations', 'LOCATION LIST');
        });
      }
    }
  }

  buildLocation = (selectedLocation) => {
    const locationToSave = {
      ...selectedLocation,
      type: selectedLocation.type,
      name: selectedLocation.lifecycleStatus === LIFECYCLE_STATUS.PREBUILT ? 
        `PREBUILT_${selectedLocation[HARDWARE_FIELDS.POD_DEVICE_SERIAL] || selectedLocation[HARDWARE_FIELDS.TTLOCK_SERIAL] }` : selectedLocation.name,
      description: selectedLocation.lifecycleStatus === LIFECYCLE_STATUS.PREBUILT && !selectedLocation.description ?
        `Prebuilt created ${moment().format(DATE_FORMAT.DISPLAY) }` : selectedLocation.description,
      latitude: selectedLocation.latitude || DEFAULT_LOCATION.latitude,
      longitude: selectedLocation.longitude || DEFAULT_LOCATION.longitude,
      accessCodes: selectedLocation.accessCodes || [],
      amenityOutlet: selectedLocation.amenityOutlet || false,
      amenityLock: selectedLocation.amenityLock || false,
      amenityPrivacy: selectedLocation.amenityPrivacy || false,
      amenitySpace: selectedLocation.amenitySpace || false,
      amenitySink: selectedLocation.amenitySink || false,
      pinCodes: selectedLocation.pinCodes || [],
      // Save null instead of empty string to avoid unique key constraint
      [HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER]: selectedLocation[HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER] || null,
    };

    delete locationToSave.reviews;

    const clearValues = (fields) => {
      fields.forEach(value => {
        // this field is a boolean so this needs to be set as false for db to accept
        if(value === HARDWARE_FIELDS.DISABLE_DATA_COLLECTION){
          locationToSave[value] = undefined;
        } else if (value === HARDWARE_FIELDS.PIN_CODES) {
          delete locationToSave[value];
        } else {
          locationToSave[value] = null;
        } 
        
      });
    };

    // If lacation space (non-mamava location), clear out all the non-location fields
    if (selectedLocation.type === LOCATION_TYPES.LOCATION) {
      // iterate over all hardware fields and set to null
      clearValues(HARDWARE_FIELDS_ALL);
      return locationToSave;
    }

    // if smart pod, iterate over the non-smart-pod hardware and null out
    // etc for the other hardware types (ttlock and smart lock)
    let fieldsToClear = [];
    switch (locationToSave.podHardwareType) {
      // The same fields apply to all types of SMART_POD: 
      // SMART_POD, SMART_POD_NO_CONTROLS, and SMART_POD_NO_FAN_CONTROLS
      case HARDWARE_TYPES.SMART_POD:
      case HARDWARE_TYPES.SMART_POD_NO_FAN_CONTROLS:
      case HARDWARE_TYPES.SMART_POD_NO_CONTROLS: {
        fieldsToClear = HARDWARE_FIELDS_ALL.filter((field) => {
          // the fields to clear should be all fields that aren't part of the smart pod fields
          return HARDWARE_FIELDS_SMART_POD.indexOf(field) === -1;
        });
        break;
      }

      case HARDWARE_TYPES.NO_TECH_POD: {
        fieldsToClear = HARDWARE_FIELDS_ALL.filter((field) => {
          // the fields to clear should be all fields that aren't part of the no tech pod fields
          return HARDWARE_FIELDS_NO_TECH_POD.indexOf(field) === -1;
        });
        break;
      }

      case HARDWARE_TYPES.SMART_LOCK: {
        fieldsToClear = HARDWARE_FIELDS_ALL.filter((field) => {
          // the fields to clear should be all fields that aren't part of the smart lock fields
          return HARDWARE_FIELDS_SMART_LOCK.indexOf(field) === -1;
        });
        break;
      }

      case HARDWARE_TYPES.TTLOCK: {
        fieldsToClear = HARDWARE_FIELDS_ALL.filter((field) => {
          // the fields to clear should be all fields that aren't part of the ttlock fields
          return HARDWARE_FIELDS_TTLOCK.indexOf(field) === -1;
        });
        break;
      }

      default:
        break;
    }

    clearValues(fieldsToClear);

    return locationToSave;
  };

  moveImageToIndex = async (locationPhotoId, newIndex) => {
    this.setState({ imageLoading: true, imageGrow: false });
    const selectedLocation = { ...this.state.selectedLocation };
    let locationPhotos = [...selectedLocation.locationPhotos];

    const oldIndex = locationPhotos.findIndex((photo) => photo.id === locationPhotoId);

    if (newIndex > -1 && newIndex < locationPhotos.length && oldIndex > -1) {
      // Move photo to new position in the array
      locationPhotos.splice(newIndex, 0, locationPhotos.splice(oldIndex, 1)[0]);
      // Get array of location ids
      const locationPhotoIds = locationPhotos.map((photo) => photo.id);
      // Set new index value for each photo
      selectedLocation.locationPhotos = locationPhotos.map((photo, index) => {
        return {
          ...photo,
          index: index + 1,
        };
      });
      await this.props.updateLocationPhotosOrder(selectedLocation.id, locationPhotoIds);

      this.setState({ selectedLocation, imageLoading: false, imageGrow: true });
    }
  };

  moveImageUp = async (locationPhotoId) => {
    const oldIndex = this.state.selectedLocation.locationPhotos.findIndex((photo) => photo.id === locationPhotoId);
    // Can't move first image up
    if (oldIndex > 0) {
      await this.moveImageToIndex(locationPhotoId, oldIndex - 1);
    }
  };

  moveImageDown = async (locationPhotoId) => {
    const oldIndex = this.state.selectedLocation.locationPhotos.findIndex((photo) => photo.id === locationPhotoId);
    // Can't move last image down
    if (oldIndex < this.state.selectedLocation.locationPhotos.length - 1) {
      await this.moveImageToIndex(locationPhotoId, oldIndex + 1);
    }
  };

  removeImage = async (locationPhotoId) => {
    const selectedLocation = { ...this.state.selectedLocation };
    let locationPhotos = [...selectedLocation.locationPhotos];
    let idx = -1;
    for (let i = 0, len = locationPhotos.length; i < len; i++) {
      if (locationPhotos[i].id === locationPhotoId) {
        idx = i;
        break;
      }
    }
    if (idx > -1 && window.confirm('Are you sure you want to delete this image? The image file will be permanently deleted.')) {
      this.setState({ imageLoading: true });
      // Delete the image!
      await this.props.removeLocationPhoto(selectedLocation.id, locationPhotoId);
      locationPhotos.splice(idx, 1);
      selectedLocation.locationPhotos = locationPhotos;
      this.setState({ selectedLocation, imageLoading: false });
    }
  };

  approveImage = async (locationPhotoId) => {
    this.setState({ imageLoading: true });
    const selectedLocation = { ...this.state.selectedLocation };
    let locationPhotos = [...selectedLocation.locationPhotos];
    let idx = -1;
    for (let i = 0, len = locationPhotos.length; i < len; i++) {
      if (locationPhotos[i].id === locationPhotoId) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      await this.props.changeLocationPhotoStatus(selectedLocation.id, locationPhotoId, 'APPROVED');
      locationPhotos[idx].status = 'APPROVED';
      selectedLocation.locationPhotos = locationPhotos;
      this.setState({ selectedLocation, imageLoading: false });
    }
  };
  denyImage = async (locationPhotoId) => {
    this.setState({ imageLoading: true });
    const selectedLocation = { ...this.state.selectedLocation };
    let locationPhotos = [...selectedLocation.locationPhotos];
    let idx = -1;
    for (let i = 0, len = locationPhotos.length; i < len; i++) {
      if (locationPhotos[i].id === locationPhotoId) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      await this.props.changeLocationPhotoStatus(selectedLocation.id, locationPhotoId, 'REJECTED');
      locationPhotos[idx].status = 'REJECTED';
      selectedLocation.locationPhotos = locationPhotos;
      this.setState({ selectedLocation, imageLoading: false });
    }
  };

  updatePodSoftware = async () => {
    if (window.confirm(`Are you sure you want to update the software on ${this.state.selectedLocation.name}?`)) {
      let response = await this.props.updatePodSoftware(this.state.selectedLocation.id);

      if (response.type === UPDATE_SOFTWARE_SUCCESS) {
        this.props.handleToastMessage('Software update triggered.');
      } else {
        this.props.handleToastMessage(`Failed to trigger software update: ${response.messages ? response.messages[0] : ''}`, true);
      }
    }
  };

  handleSave = async () => {
    let response = null;

    if (this.state.isCreate) {
      response = await this.props.createLocation(this.buildLocation(this.state.selectedLocation));
    } else {
      response = await this.props.updateLocation(this.state.selectedLocation.id, this.buildLocation(this.state.selectedLocation));
    }

    if (response.type === UPDATE_LOCATION_SUCCESS) {
      this.props.handleToastMessage('Location updated.');
      this.props.setDirtyForm(false);
      this.props.setPageTitle(`${response.response.type === LOCATION_TYPES.MAMAVA ? 'Mamava Pod' : 'Lactation Space'}: ${response.response.name}`);
      this.setState({ selectedLocation: response.response });
    } else if (response.type === CREATE_LOCATION_SUCCESS) {
      console.log(response);
      this.props.handleToastMessage('Location updated.');
      this.props.setDirtyForm(false);
      this.props.history.push(`/locations/${response.response.id}`);
      this.setState({isCreate: false});
      this.props.setPageTitle(`${response.response.type === LOCATION_TYPES.MAMAVA ? 'Mamava Pod' : 'Lactation Space'}: ${response.response.name}`);
      this.setState({selectedLocation: response.response});
    } else {
      const message = first(get(response, 'messages', []));
      this.props.handleToastMessage(`Failed to update location: ${message}`, true);
    }
  };

  handleApprove = async () => {
    const response = await this.props.approveLocation(this.state.selectedLocation);
    if (response.type === APPROVE_LOCATION_SUCCESS) {
      this.props.handleToastMessage('Location approved.');
      this.setState({ selectedLocation: response.response });
    } else {
      const message = first(get(response, 'messages', []));
      this.props.handleToastMessage(`Failed to approve location: ${message}`, true);
    }
  };

  handleToggle = (name) => (event) => {
    this.props.setDirtyForm(true);

    let selectedLocation = this.state.selectedLocation;
    selectedLocation[name] = !selectedLocation[name];

    this.setState({
      selectedLocation,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = ({ target: { value, name } }) => {
    this.props.setDirtyForm(true);

    const selectedLocation = { ...this.state.selectedLocation };
    selectedLocation[name] = value;
    if (name === LOCATION_FIELDS.POD_HARDWARE_TYPE) {
      setPodHardwareTypeDefaultValues(selectedLocation, value);
    }
    // Add promise so you can await the change
    const changePromise = new Promise((resolve) => {
      this.setState(
        {
          selectedLocation,
        },
        () => resolve()
      );
    });
    return changePromise;
  };

  addImage = async e => {
    const file = e.target.files[0];
    if (file) {
      this.setState({ imageLoading: true });

      try {
        const {
          response: { imageRef, url }
        } = await this.props.uploadLocationPhoto(this.state.selectedLocation.id, file);
        const resp = await this.props.addLocationPhoto(this.state.selectedLocation.id, imageRef);

        const selectedLocation = { ...this.state.selectedLocation };
        let locationPhotos = selectedLocation.locationPhotos ? [...selectedLocation.locationPhotos] : [];
        locationPhotos.push({
          id: resp.response.id,
          imageRef,
          url,
          index: 1000,
          status: 'APPROVED'
        });
        selectedLocation.locationPhotos = locationPhotos;
        this.setState({
          imageLoading: false,
          selectedLocation
        });
      } catch (e) {
        this.setState({
          imageLoading: false
        });
        // Present toast!
        this.props.handleToastMessage('An error occurred while uploading the file; please try again.', true);
      }
    }
  };

  onSelectedLocationUpdate = (updatedLocation) => {
    this.setState({ selectedLocation: updatedLocation });
  };

  changeTabs = (event, value) => {
    let { location, history } = this.props;
    this.setState({ selectedTab: value });
    const pathname = location.pathname;
    history.push({
      pathname,
      search: value !== TABS.LOCATION_DETAILS ? `?tab=${value}` : '',
    });
  };

  render() {
    let { classes, isLoading, hasDirtyForm } = this.props;
    let { selectedTab, selectedLocation } = this.state;
    if (!isEmpty(selectedLocation)) {
      // If location type is not set, ask user to select it
      if (!selectedLocation.type) {
        return <CreateLocationDialog onChange={this.handleChange} />;
      }
      
      // If location type is set, display the rest of the form
      return (
        <Fragment>
          <Prompt when={hasDirtyForm} message={() => 'You have unsaved changes. Clicking OK will not save these changes.'} />

          {this.props.isLoading && <div className="loader" />}
          {!isLoading && (
            <Fragment>
              <LocationToolbar
                hasDirtyForm={hasDirtyForm}
                handleSave={this.handleSave}
                selectedTab={selectedTab}
                changeTabs={this.changeTabs}
                selectedLocation={selectedLocation}
                isCreate={this.state.isCreate}
                onChange={this.handleChange}
                isAdminMode={this.state.isAdminMode}
                onAdminModeChange={isAdminMode => this.setState({ isAdminMode })}
                handleApprove={this.handleApprove}
              />
              <div className={classes.viewWrapper}>

                {selectedTab === TABS.LOCATION_DETAILS && (
                  <Fragment>
                    {(() => {
                      switch (selectedLocation.lifecycleStatus) {
                        case LIFECYCLE_STATUS.PREBUILT:
                          return (
                            <EditPrebuiltLocation
                              selectedLocation={this.state.selectedLocation}
                              onChange={this.handleChange}
                            />
                          );
                        default:
                          return (
                            <EditLocation
                              selectedLocation={this.state.selectedLocation}
                              onChange={this.handleChange} 
                              isAdminMode={this.state.isAdminMode}
                              onSelectedLocationUpdate={this.onSelectedLocationUpdate}
                            />
                          );
                      }
                    })()}
                     
                    <PhotoCard
                      selectedLocation={this.state.selectedLocation}
                      imageLoading={this.state.imageLoading}
                      moveImageUp={this.moveImageUp}
                      removeImage={this.removeImage}
                      moveImageDown={this.moveImageDown}
                      denyImage={this.denyImage}
                      approveImage={this.approveImage}
                      addImage={this.addImage}
                      acceptType={this.state.acceptType}
                    />
                    
                  </Fragment>
                )}

                {selectedTab === TABS.REVIEWS && (
                  <ReviewComponent selectedLocation={this.props.selectedLocation} />
                )}

                {selectedTab === TABS.OVERVIEW && <MonitoringOverviewComponent />}

                {selectedTab === TABS.DEBUG && <MonitoringDebug />}
              </div>
            </Fragment>
          )}
        </Fragment>
      );
    }

    if (isLoading && isEmpty(this.state.selectedLocation) && isEmpty(this.props.selectedLocation)) {
      return <div className="loader" />;
    }

    if (!isLoading && isEmpty(this.state.selectedLocation) && isEmpty(this.props.selectedLocation)) {
      return (
        <div className={classes.notFoundMessage}>
          <Typography variant="h5">Could not find that location.</Typography>
        </div>
      );
    }

    return null;
  }
}

LocationContainer.propTypes = {
  selectedLocation: PropTypes.object,
  hasDirtyForm: PropTypes.bool,

  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  location: PropTypes.object,

  setPageTitle: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  createLocation: PropTypes.func.isRequired,
  rejectLocation: PropTypes.func.isRequired,
  updatePodSoftware: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  addLocationPhoto: PropTypes.func.isRequired,
  updateLocationPhotosOrder: PropTypes.func.isRequired,
  removeLocationPhoto: PropTypes.func.isRequired,
  changeLocationPhotoStatus: PropTypes.func.isRequired,
  uploadLocationPhoto: PropTypes.func.isRequired,
  approveLocation: PropTypes.func,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    selectedLocationReviews: state.location.get('selectedLocationReviews'),
    isLoading: state.location.get('isLoading'),
    errors: state.location.get('errors'),
    hasDirtyForm: state.layout.get('hasDirtyForm'),
  };
};

const TOOLBAR_HEIGHT = 64;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.primary.background,
    overflowY: 'scroll',
  },
  viewWrapper: {
    paddingTop: TOOLBAR_HEIGHT,
    backgroundColor: Colors.primary.background,
  },
  notFoundMessage: {
    textAlign: 'center',
    marginTop: '2rem',
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    getLocation,
    updateLocation,
    createLocation,
    rejectLocation,
    updatePodSoftware,
    handleToastMessage,
    setDirtyForm,
    addLocationPhoto,
    updateLocationPhotosOrder,
    removeLocationPhoto,
    changeLocationPhotoStatus,
    uploadLocationPhoto,
    approveLocation,
  })
);

export default prepareForExport(LocationContainer);
