import { HARDWARE_TYPES, PCD_HARDWARE_TYPES } from '../../../types/location.types';

export const HARDWARE_FIELDS = {
  HOLOGRAM_DEVICE_ID: 'hologramDeviceId',
  PCD_SHARED_SECRET: 'pcdSharedSecret',
  PIN_CODES: 'pinCodes',
  POD_DEVICE_SERIAL: 'podDeviceSerial',
  POD_HARDWARE_IDENTIFIER: 'podHardwareIdentifier',
  POD_MODEM_SERIAL: 'podModemSerial',
  POD_SOFTWARE_VERSION: 'podSoftwareVersion',
  PCD_SIM: 'pcdSim',
  TTLOCK_CONNECTION_STRING: 'ttLockConnectionString',
  TTLOCK_HARDWARE_IDENTIFIER: 'ttLockHardwareIdentifier',
  TTLOCK_NAME: 'ttLockName',
  PCD_HARDWARE_TYPE: 'pcdHardwareType',
  DISABLE_DATA_COLLECTION: 'disableDataCollection',
  TTLOCK_SERIAL: 'lockSerial',
  TTLOCK_MODEL: 'ttLockModel',
  REPLACE_LOCK: 'replaceLock',
};

export const HARDWARE_FIELDS_ALL = Object.values(HARDWARE_FIELDS);

export const HARDWARE_FIELDS_PCD_PAIRED_TTLOCK = [
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING,
  HARDWARE_FIELDS.TTLOCK_NAME,
  HARDWARE_FIELDS.POD_SOFTWARE_VERSION,
  HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.POD_MODEM_SERIAL,
  HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID,
  HARDWARE_FIELDS.PCD_SHARED_SECRET,
  HARDWARE_FIELDS.PCD_SIM,
  HARDWARE_FIELDS.PCD_HARDWARE_TYPE,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
  HARDWARE_FIELDS.TTLOCK_SERIAL,
  HARDWARE_FIELDS.TTLOCK_MODEL,
  HARDWARE_FIELDS.REPLACE_LOCK,
];

export const HARDWARE_FIELDS_TTLOCK = [
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING,
  HARDWARE_FIELDS.TTLOCK_NAME,
  HARDWARE_FIELDS.PCD_SIM,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
  HARDWARE_FIELDS.TTLOCK_SERIAL,
  HARDWARE_FIELDS.TTLOCK_MODEL,
];

export const HARDWARE_FIELDS_VAROOM_TTLOCK = [
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING,
  HARDWARE_FIELDS.TTLOCK_NAME,
  HARDWARE_FIELDS.TTLOCK_SERIAL,
  HARDWARE_FIELDS.TTLOCK_MODEL,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.REPLACE_LOCK,
];

export const HARDWARE_FIELDS_SMART_LOCK = [
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
  HARDWARE_FIELDS.REPLACE_LOCK,
];

// The same fields apply to all types of SMART_POD:
// SMART_POD, SMART_POD_NO_CONTROLS, and SMART_POD_NO_FAN_CONTROLS
export const HARDWARE_FIELDS_SMART_POD = [
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.POD_SOFTWARE_VERSION,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.POD_MODEM_SERIAL,
  HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID,
  HARDWARE_FIELDS.PCD_SHARED_SECRET,
  HARDWARE_FIELDS.PCD_SIM,
  HARDWARE_FIELDS.PCD_HARDWARE_TYPE,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
];

export const HARDWARE_FIELDS_ZTU_POD = [
  HARDWARE_FIELDS.PCD_HARDWARE_TYPE,
  HARDWARE_FIELDS.POD_DEVICE_SERIAL,
  HARDWARE_FIELDS.POD_MODEM_SERIAL,
  HARDWARE_FIELDS.POD_SOFTWARE_VERSION,
  HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER,
  HARDWARE_FIELDS.PIN_CODES,
  HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
];

export const HARDWARE_FIELDS_NO_TECH_POD = [HARDWARE_FIELDS.POD_DEVICE_SERIAL];

export const shouldShowHardwareField = (field, location) => {
  const smartPodHardwareTypes = [HARDWARE_TYPES.SMART_POD, HARDWARE_TYPES.SMART_POD_NO_CONTROLS, HARDWARE_TYPES.SMART_POD_NO_FAN_CONTROLS];
  const particleExclusionFields = [HARDWARE_FIELDS.PCD_SIM, HARDWARE_FIELDS.PCD_SHARED_SECRET, HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID];

  let podHardwareType = location.podHardwareType;

  let selectedFieldSet = [];
  // The same fields apply to all types of SMART_POD:
  // SMART_POD, SMART_POD_NO_CONTROLS, and SMART_POD_NO_FAN_CONTROLS

  if (smartPodHardwareTypes.includes(podHardwareType)) {
    selectedFieldSet = HARDWARE_FIELDS_SMART_POD;
  } else if (podHardwareType === HARDWARE_TYPES.NO_TECH_POD) {
    selectedFieldSet = HARDWARE_FIELDS_NO_TECH_POD;
  } else if (podHardwareType === HARDWARE_TYPES.SMART_LOCK) {
    selectedFieldSet = HARDWARE_FIELDS_SMART_LOCK;
  } else if (podHardwareType === HARDWARE_TYPES.TTLOCK) {
    selectedFieldSet = HARDWARE_FIELDS_TTLOCK;
  } else if (podHardwareType === HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK) {
    selectedFieldSet = HARDWARE_FIELDS_PCD_PAIRED_TTLOCK;
  } else if (podHardwareType === HARDWARE_TYPES.VAROOM_TTLOCK) {
    selectedFieldSet = HARDWARE_FIELDS_VAROOM_TTLOCK;
  } else if (podHardwareType === HARDWARE_TYPES.ZTU_POD) {
    selectedFieldSet = HARDWARE_FIELDS_ZTU_POD;
  }

  if (location.pcdHardwareType === PCD_HARDWARE_TYPES.PARTICLE && particleExclusionFields.includes(field)) {
    return false;
  }

  return selectedFieldSet.indexOf(field) > -1;
};


export const setPodHardwareTypeDefaultValues = (selectedLocation, value) => {
  const baseDefaultHardwareFieldValueDefinition = [{
    key: HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
    value: false,
  }];

  const defaultHardwareFieldValueDefinition = {
    [HARDWARE_TYPES.ZTU_POD]: [{
      key: HARDWARE_FIELDS.DISABLE_DATA_COLLECTION,
      value: true,
    }],
  };

  let valueDefinition = defaultHardwareFieldValueDefinition[value] || [];
  valueDefinition = [
    ...baseDefaultHardwareFieldValueDefinition,
    ...valueDefinition,
  ];
  valueDefinition.forEach(({ key, value }) => {
    selectedLocation[key] = value;
  });
};