import { HARDWARE_FIELDS } from '../modules/location/editComponents/hardwareFields';
import { HARDWARE_TYPES } from '../types/location.types';
import { isEmpty } from 'lodash';

export const AMAZON_FIELDS = [
  {
    columns: 12,
    label: 'Amazon Project Type',
    key: 'amazonProjectType',
  },
  {
    columns: 6,
    label: 'Amazon Network Type',
    key: 'amazonNetworkType',
  },
  {
    columns: 6,
    label: 'Amazon Site Code',
    key: 'amazonSiteCode',
  },
];

export const isTTLockType = location => location.podHardwareType === HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK || location.podHardwareType === HARDWARE_TYPES.VAROOM_TTLOCK; 

export const isInitializedTTLock = location => !!location[HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING];

export const isFalsyOrEmpty = value => {
  return value === undefined || value === null || (typeof value === 'string' && isEmpty(value));
};
